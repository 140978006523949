import { getLoyaltyProgram as getLoyaltyProgramRequest } from '@wix/ambassador-loyalty-v1-program/http';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { ControllerFlowAPI, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export async function getLoyaltyProgram(
  flowAPI: PlatformControllerFlowAPI | ControllerFlowAPI,
): Promise<LoyaltyProgram> {
  const { httpClient } = flowAPI;
  const { withErrorHandler, getResolvedError } = flowAPI.errorHandler;

  try {
    const response = await withErrorHandler(() => httpClient.request(getLoyaltyProgramRequest({})), {
      errorCodesMap: {},
    });

    return response.data.loyaltyProgram!;
  } catch (error) {
    throw getResolvedError(error);
  }
}
