import { listRewards } from '@wix/ambassador-loyalty-v1-reward/http';
import { Reward as LoyaltyReward } from '@wix/ambassador-loyalty-v1-reward/types';
import { ControllerFlowAPI, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export async function getLoyaltyRewards(
  flowAPI: PlatformControllerFlowAPI | ControllerFlowAPI,
  redemptionContext = false,
): Promise<LoyaltyReward[]> {
  const { httpClient } = flowAPI;
  const { withErrorHandler, getResolvedError } = flowAPI.errorHandler;

  try {
    const response = await withErrorHandler(
      () =>
        httpClient.request(
          listRewards({
            ...(redemptionContext && { redemptionContext: true }),
          }),
        ),
      {
        errorCodesMap: {},
      },
    );

    return response.data.rewards ?? [];
  } catch (error) {
    throw getResolvedError(error);
  }
}
