import { applyDiscountToCheckout } from '@wix/ambassador-loyalty-checkoutexchange-v1-loyalty-checkout-discount/http';
import { Reward as LoyaltyReward } from '@wix/ambassador-loyalty-v1-reward/types';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

interface ApplyFlexibleRewardParams {
  flowAPI: PlatformControllerFlowAPI;
  checkoutId: string;
  flexibleReward: LoyaltyReward;
  pointsToSpend: number;
}

export async function applyFlexibleReward({
  flowAPI,
  checkoutId,
  flexibleReward,
  pointsToSpend,
}: ApplyFlexibleRewardParams): Promise<void> {
  const { httpClient } = flowAPI;
  const { withErrorHandler } = flowAPI.errorHandler;

  await withErrorHandler(
    () =>
      httpClient.request(
        applyDiscountToCheckout({
          checkoutId,
          reward: {
            id: flexibleReward?.id!,
            revision: flexibleReward?.revision!,
            pointsToSpend,
          },
        }),
      ),
    { errorCodesMap: {} },
  );
}
