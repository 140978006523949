import { getCheckout as getCheckoutRequest } from '@wix/ambassador-ecom-v1-checkout/http';
import { Checkout } from '@wix/ambassador-ecom-v1-checkout/types';
import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export async function getCheckout(flowAPI: PlatformControllerFlowAPI, checkoutId: string): Promise<Checkout> {
  const { httpClient } = flowAPI;
  const { withErrorHandler } = flowAPI.errorHandler;

  const response = await withErrorHandler(
    () =>
      httpClient.request(
        getCheckoutRequest({
          id: checkoutId,
        }),
      ),
    { errorCodesMap: {} },
  );

  return response.data.checkout!;
}
