import { getCurrentMemberCoupons } from '@wix/ambassador-loyalty-v1-coupon/http';
import { LoyaltyCoupon } from '@wix/ambassador-loyalty-v1-coupon/types';
import { ControllerFlowAPI, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export async function getLoyaltyCoupons(
  flowAPI: PlatformControllerFlowAPI | ControllerFlowAPI,
): Promise<LoyaltyCoupon[]> {
  const { httpClient } = flowAPI;
  const { withErrorHandler, getResolvedError } = flowAPI.errorHandler;

  try {
    const response = await withErrorHandler(() => httpClient.request(getCurrentMemberCoupons({})), {
      errorCodesMap: {},
    });

    return response.data.loyaltyCoupons!;
  } catch (error) {
    throw getResolvedError(error);
  }
}
