import { listEarningRules } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/http';
import { LoyaltyEarningRule } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/types';
import { ControllerFlowAPI, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export async function getLoyaltyEarningRules(
  flowAPI: PlatformControllerFlowAPI | ControllerFlowAPI,
): Promise<LoyaltyEarningRule[]> {
  const { httpClient } = flowAPI;
  const { withErrorHandler, getResolvedError } = flowAPI.errorHandler;

  try {
    const response = await withErrorHandler(() => httpClient.request(listEarningRules({})), {
      errorCodesMap: {},
    });

    return response.data.earningRules ?? [];
  } catch (error) {
    throw getResolvedError(error);
  }
}
