import { getCurrentMemberAccount } from '@wix/ambassador-loyalty-v1-account/http';
import { LoyaltyAccount } from '@wix/ambassador-loyalty-v1-account/types';
import { ControllerFlowAPI, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export async function getLoyaltyAccount(
  flowAPI: PlatformControllerFlowAPI | ControllerFlowAPI,
): Promise<LoyaltyAccount> {
  const { httpClient } = flowAPI;
  const { withErrorHandler, getResolvedError } = flowAPI.errorHandler;

  try {
    const response = await withErrorHandler(() => httpClient.request(getCurrentMemberAccount({})), {
      errorCodesMap: {},
    });

    return response.data.account!;
  } catch (error) {
    // Loyalty account doesn't exist (user has no points)
    if (error instanceof Error && error.message.includes('404')) {
      return {};
    }

    throw getResolvedError(error);
  }
}
